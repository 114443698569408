import type { PaymentRemoteComponentProps } from '@patrianna-payments/payment-remote-entry'
import { PaymentRemoteEntry } from '@patrianna-payments/payment-remote-entry'
import { captureException } from '@sentry/nextjs'

import { useTranslation } from '@patrianna/core-components'
import deps from 'package.json'
import { getTemporaryToken } from 'services/tokens/api'
import { serviceDependencies } from 'src/services'
import ROUTES from 'temp/routes.json'

const url = process.env.PAYMENT_MF_HOST
const allowedPages = [ROUTES.SWEEPSTAKE_RULES, ROUTES.TERMS, ROUTES.PLAYER_SAFETY, ROUTES.PRIVACY, ROUTES.OTP]

const RemoteEntryPayment = <T extends Record<string, unknown> = Record<string, unknown>>(
  props: PaymentRemoteComponentProps<T>
) => {
  const t = useTranslation()

  const handleError = (error: any) => {
    if (process.env.PROFILE === 'prod') {
      captureException({
        title: '[PAYMENT REMOTE ENTRY]',
        message: error,
      })
    }
    console.error('[PAYMENT REMOTE ENTRY] - script loading error', error)
  }

  const onModuleLoadError = (error: any) => {
    if (process.env.PROFILE === 'prod') {
      captureException({
        title: '[PAYMENT MODULE LOADING ERROR]',
        message: error,
      })
    }
    console.error("[PAYMENT MODULE LOADING ERROR] - can't find module", error)
  }

  return (
    <PaymentRemoteEntry
      // otp allowed routes
      allowedRoutes={allowedPages}
      indexRoute={ROUTES.HOME}
      otpRoute={ROUTES.OTP}
      brandName='payment'
      t={t}
      dependencies={deps.dependencies}
      onScriptLoadError={handleError}
      onModuleLoadError={onModuleLoadError}
      errorHandler={serviceDependencies.errorHandler}
      url={url}
      ssr={false}
      getTemporaryToken={getTemporaryToken}
      fraud={serviceDependencies.fraud}
      payment={serviceDependencies.payment}
      {...props}
    />
  )
}

export default RemoteEntryPayment
